body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #eff2f6;
}

.bold {
  font-weight: 500;
}
.dark {
  font-weight: 300;
}

.logo {
  width: 60px;
}

header {
  padding: 4px 0;
  background-color: #fff;
  border-bottom: 2px solid #fdc513;
}

.nav {
  display: flex;
  align-items: center;
  height: 100%;
}
.nav a {
  padding: 0 10px;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  color: #716c6c;
  font-size: 14px;
}
.nav a.active {
  color: #fdc513;
}
.nav a.donate-now {
  padding: 10px;
  box-shadow: 2px 2px 5px #f2f2f2;
}
.nav .not-container {
  width: 330px;
}
.nav .profile {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  width: 75px;
  float: left;
}
.nav .kid-details {
  vertical-align: top;
  font-size: 1.25rem;
  padding: 5px 10px 0px 115px;
}
.nav .kid-details .sub {
  font-size: 1rem;
  color: grey;
}

.loginContainer .loginForm .btn-primary,
.btn-primary {
  background: #fdc513;
  color: #000000;
  /* border-radius: 4px; */
  border-radius: 49px;
  padding: 6px 12px;
  border: none;
  transition: 0.5s;
}
.loginContainer .loginForm .btn-primary:hover,
.btn-primary:hover {
  background: #000000;
  color: #fdc513;
}
button {
  box-shadow: 2px 2px 5px #bfaaaa;
}

.nav a.active::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom-style: solid;
  border-bottom-width: 10px;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  color: #fdc513;
  margin: auto;
}
.card img {
  border-radius: 8px 8px 0px 0;
}
.kid-img {
  height: 262px;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.kid-img img {
  transition: all 0.5s ease-in-out;
}
.kid-img:hover::after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.kid-img button {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -200px;
  margin: auto;
  transition: all 0.5s ease-in-out;
  z-index: 1;
  width: 130px;
  border: none;
  border-radius: 10px;
  padding: 6px;
  font-weight: 600;
  color: #fff;
}
.kid-img:hover img {
  scale: 1.1;
}
.kid-img:hover button {
  opacity: 1;
  bottom: 10px;
}

.categories {
  background-color: #e5edf0;
  margin: 10px 0;
  padding: 4px;
  border-radius: 50px;
  display: inline-flex;
  font-size: 14px;
}
.category-container {
  background-color: #fff;
  padding: 30px 0;
}
.categories button {
  border-radius: 50px;
  padding: 8px 12px;
  display: inline-block;
  margin: 0;
  border: none;
  font-weight: 600;
  cursor: pointer;
  background: none;
  box-shadow: none;
  position: relative;
}

.categories button.active {
  background: radial-gradient(
    circle at 10% 20%,
    rgb(255, 200, 124) 0.1%,
    rgb(252, 251, 121) 90%
  );
}

.category-container .card .name {
  font-weight: 700;
}
.category-container button .badge {
  position: absolute;
  right: -2px;
  top: -2px;
}

.badge {
  background-color: #fdc513;
  border-radius: 50px;
  font-size: 9px;
}

.banner {
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgb(250, 250, 250) 0%,
    rgb(225, 234, 238) 90%
  );
}
.swiper-conainer,
.swiper-slide img {
  height: 350px;
  padding: 20px 0;
}

.profile-wrapper .cover-img {
  background: #fdc513;
  height: 270px;
  margin-bottom: 3rem;
}
.profile-wrapper .profile-img {
  width: 8rem;
  height: 8rem;
  background: #ffc107;
  border: 2px solid #fff;
  border-radius: 100px;
  position: relative;
  bottom: 55px;
  overflow: hidden;
}
.profile-wrapper .profile-img > img {
  border-radius: 100px;
}
.profile-wrapper .kid-name-wraper {
  margin: 15px 25px 0 10px;
}
.profile-wrapper .kid-name-wraper h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0;
}
.profile-wrapper .unique-id {
  font-size: 14px;
  color: #676a79;
  font-weight: 300;
}
.profile-wrapper .details {
  position: relative;
  bottom: 23px;
  font-weight: 300;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 15px;
}
.profile-wrapper .react-tabs__tab-list {
  border-bottom: 1px solid #fdc513;
  margin-bottom: 0;
}
.profile-wrapper .react-tabs__tab--selected {
  border: none;
  border-bottom: 3px solid #fdc513;
}
.profile-wrapper .react-tabs__tab-panel {
  padding: 15px;
}
.profile-wrapper .react-tabs__tab-panel--selected {
  border: 1px solid #fdc513;
}
.text-muted {
  color: #716c6c;
  font-weight: 200;
}
.small-fonts {
  font-size: 0.775em;
}
.login-option {
  position: absolute;
  top: 47px;
  background: #fff;
  width: 150px;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 3px 3px #beb8b8;
  font-size: 14px;
  z-index: 9;
}
.login-option ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.login-option li {
  padding: 5px 10px;
  display: block;
  font-weight: 500;
  cursor: pointer;
}
.login-option li:hover,
.login-option li a:hover {
  color: #fdc513;
}
.login-option li a.active::after {
  content: "";
  border: none;
}
.login-option li a {
  color: #000;
  padding: 0;
  font-weight: 500;
}
.login-option li i {
  margin-right: 5px;
}
.login-option span {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 9;
  padding: 15px;
}
.filter-btn {
  position: absolute;
  width: auto;
  bottom: 30px;
}
.range-slider__tooltip__label {
  display: none;
}
.otp-title + div {
  justify-content: space-between;
}
.register-otp-wrapper {
  position: relative;
}

.datatable {
  height: calc(100vh - 180px);
  padding: 20px;
}
.datatableTitle {
  width: 100%;
  font-size: 24px;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.link {
  text-decoration: none;
  color: rgb(10, 16, 10);
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.cellWithImg {
  display: flex;
  align-items: center;
}
.cellImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;
}
.viewButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: darkblue;
  border: 1px dotted rgba(0, 0, 139, 0.596);
  cursor: pointer;
}
.editButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: darkgreen;
  border: 1px dotted rgba(0, 139, 69, 0.596);
  cursor: pointer;
}

.deleteButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
div.MuiDataGrid-footerContainer {
  display: none;
}

.form-control {
  appearance: auto !important;
}
.img-overlay{
  position: fixed;
  background: rgba(0,0,0,0.5);
  left: 0;
  border: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  top: 0;
  padding:20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-overlay .close-img{
  position: absolute;
  right: 30px;
  font-size: 30px;
  color: #fff;
  top: 10px;
  cursor: pointer;
}
.profile-img{
  height: 250px;
  width: 90%;
  border: 1px solid #e2e2e2;
  text-align: center;
  border-radius: 8px 8px 0 0px;
}
.profile-img img{
  height: 100%;
  object-fit: scale-down;
}
.profile-img i.fa-user{
  font-size: 230px;
  text-align: center;
  color: #888;
}
.upload-btn{
  width: 90%;
  background-color: #ffc107;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
div.modal-certificate.ReactModal__Content--after-open {
  width: 600px;
  padding: 20px;
  background-color: #fff;
}
button.receipt-download{
    position: relative;
    top: -2px;
    z-index: 1;
    right: 0;
    background-color: #fdc513;
}
.receipt{
    border: 1px solid gray;
    border-top: none;
    position: relative;
    color: #000;
    background: #fff;
}
.receipt-header{
    background: url('../public/img/receiptHeader.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    height: 195px;
}
.receipt-header .logo{
    margin: 30px 20px 50px 50px;
}
.receipt-header .content{
    background: url('../public/img/header1.png');
    height: 81%;
    z-index: 99;
    background-position: right;
    background-size: cover;
    right: 0;
    padding: 20px 40px;
    width: 100%;
    box-sizing: border-box;
}
.receipt-header .content h2{
    font-weight: 900;
    font-size: 20px;
}
.receipt-header .content p{
    margin-bottom: 3px;
    font-size: 12px;
}
.receipt .form-content{
    padding:25px 50px;
    font-size: 13px;
    font-weight: 400;
    background: url('../public/img/sign.png') no-repeat;
    background-size: contain;
    background-position: center;
}
.receipt .quote-left{
    left: 27px;
    top: -25px;
}
.receipt .quote-right, .receipt .quote-left{
    position: absolute;
    width: 60px;
    right: 11px;
    z-index: 1;
    bottom: -22px;
}
.receipt .field{
    margin-bottom: 10px;
    display: flex;
    width: 100%;
}
.receipt .field span:first-child{
    font-weight: 500;
    white-space: nowrap;
}
.receipt .receipt-main{
    background-color: #fff;
}
.receipt .receipt-main p{
    margin: 20px 9px;
    text-align: center;
    font-size: 13px;
}
.receipt .receipt-main h1{
    text-align: center;
    font-weight: 800;
  }
 
  .receipt .receipt-main .field span:last-child{
    margin-left: 12px;
    border-bottom: 2px dotted;
    display: inline-block;
    font-weight: 700;
    flex: 1;
  }
  .receipt-footer{
    background: url('../public/img/receiptFooter.png') no-repeat;
    height: 200px;
    background-position: bottom;
    background-size: cover;
    justify-content: space-between;
    font-size: 12px;
    padding: 15px 50px;
    display: flex;
    background-color: #fff;
  }
  .receipt-footer .signature{
    background: url('../public/img/stamp.png') no-repeat;
    height: 116px;
    background-position: center;
    background-size: contain;
    position: relative;
    bottom: 20px;
    width: 200px;
  }
  .receipt-footer .signature >div{
    margin-top: 68px;
    text-align:center;
  }
  .backdrop-overlay{
    background: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}
@media screen and (max-width: 1023px) {
  .profile-wrapper .details {
    bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .swiper-conainer,
  .swiper-slide img {
    height: 200px;
  }
  div.modal-certificate.ReactModal__Content--after-open {
    width: 90%;
    padding: 20px;
    background-color: #fff;
  }
  .profile-wrapper .cover-img {
    margin-bottom: 0;
    height: auto;
  }
  .profile-wrapper .profile-img {
    height: 6rem;
    width: 6rem;
  }
  .categories {
    padding: 10px;
    flex-wrap: wrap;
  }
  .categories button {
    margin: 5px;
    border: 1px solid #bab6b6;
  }
  .nav a {
    padding: 0 4px;
    font-size: 13px;
  }
  .nav a.donate-now {
    padding: 5px 7px 2px;
    box-shadow: 2px 2px 5px #f2f2f2;
    color: #fff;
  }
  .nav a.active::after {
    display: none;
  }

  .login-option {
    right: 0;
  }
  .react-tabs__tab {
    padding: 5px;
  }
  .sponsor-btn-wrapper button {
    width: 100%;
  }
  .sponsor-btn-wrapper {
    margin-bottom: 40px;
  }
  .filter-btn {
    width: 100%;
    position: relative;
    bottom: 15px;
  }
  .ReactModal__Content--after-open {
    margin: 50% auto;
    width: 92%;
    display: block;
    transform: initial !important;
    inset: initial !important;
  }
  .logo {
    width: 40px;
  }
}
